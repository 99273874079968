import React, { Fragment } from "react";
import { Row, Col, Input, message, Spin } from "antd";
import { useParams, useHistory } from "react-router-dom";
import axios from "./CustomAxios";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const gridStyle = {
  textAlign: "center",
  height: 100,
  padding: "8px 8px",
  marginTop: "15px",
  borderRadius: "10px",
  // backgroundColor: '#ffd54f',
};
export default function Sepet({ setBreadcrumb }) {
  const params = useParams();
  const history = useHistory();

  const [loading, setLoading] = React.useState(true);

  //0 : raflar , 1: raf içindeki ürünler
  // const [sayfa, setSayfa] = React.useState(0);

  //bütün ürünler okunduysa
  const [bittimi, setBittimi] = React.useState(true);
  //seçilen raf ID
  // const [secilenRafID, setSecilenRafID] = React.useState([]);

  //çekilen raflar
  const [raflar, setRaflar] = React.useState([]);

  //okutulan ürün barkod
  const [barkod, setBarkod] = React.useState("");

  //suan okutulan rafın id (yeşil renk)
  const [suanOkutulanRaf, setSuanOkutulanRaf] = React.useState(0);

  React.useEffect(() => {
    axios
      .get("siparisler", {
        params: {
          //kullanici_id: 4,
          sepet_id: params.id,
        },
      })
      .then((result) => {
        let temp = result.data.map((item) => {
          if (item.siparis === null) {
            let ttt = { ...item, aktif: false };
            ttt.siparis = { urunler: [{ adet: 0, ayrim_adet: 0 }] };
            return ttt;
          } else {
            return { ...item, aktif: true };
          }
        });
        setRaflar(temp);
        setLoading(false);
      });
  }, [params.id]);

  const urunOkut = (e) => {
    if (e.key === "Enter") {
      setBarkod("");
      const key = "updatable";

      // e.preventDefault();

      //rafların hepsini bitirdiği zaman
      if (barkod === "bitti") {
        let toplamBitmis = 0;

        raflar.forEach((item) => {
          const toplamUrun = item.siparis.urunler.reduce(
            (a, b) => a + b.adet,
            0
          );

          const okutulanUrun = item.siparis.urunler.reduce((a, b) => {
            if (b.ayrim_adet === b.adet) {
              return a + b.adet;
            } else {
              return a;
            }
          }, 0);

          if (toplamUrun === okutulanUrun) toplamBitmis++;
        });

        if (raflar.length === toplamBitmis) {
          message.loading({ content: "Yükleniyor...", key });
          setTimeout(() => {
            setBittimi(true);
            message.success({ content: "Kayıt edildi", key, duration: 2 });
          }, 500);
        } else {
          message.error({
            content: "Okutulmamış ürün mevcut, kontrol ediniz.",
            key,
            duration: 2,
          });
        }
      } else {
        message.loading({ content: "Yükleniyor...", key });

        let temp = [...raflar];
        // let bulundu = false;
        let bulunanID = 0;

        // console.log(barkod);

        for (let i = 0; i < temp.length; i++) {
          // let result = false;
          bulunanID = 0;

          temp[i].siparis.urunler.forEach((item2, key2) => {
            if (
              (item2.barkod === barkod ||
                String(item2.varyasyon_id) === barkod) &&
              item2.ayrim_adet !== item2.adet
            ) {

              temp[i].siparis.urunler[key2].ayrim_adet += 1;

              setSuanOkutulanRaf(0);
              setTimeout(() => {
                setSuanOkutulanRaf(temp[i].id);
              }, 250);

              // result = true;
              // bulundu = true;
              bulunanID = i; //bulunan raf index

              axios
                .put("ayrimislem/" + temp[i].siparis.urunler[key2].id, {
                  adet: temp[i].siparis.urunler[key2].ayrim_adet,
                })
                .then((result) => {
                  if (result.data.status === 1) {
                    message.success({
                      content: result.data.message,
                      key,
                      duration: 2,
                    });
                  } else {
                    message.error({
                      content: "Veri tabanı İşlem sırasında bir hata oluştu",
                      key,
                      duration: 2,
                    });
                  }
                });
            }
          });
          if (bulunanID !== 0) {
            break;
          }
        }

        console.log(bulunanID,'Bulamadııım')
        if (bulunanID !== 0) {
          message.success({
            content: "Ürün " + temp[bulunanID].raf_adi + " Rafında",
            key,
            duration: 2,
          });
        } else {
          message.error({ content: "Ürün Bulunamadı!", key, duration: 2 });
        }

        // if (bulundu === true) {
        //   message.success({
        //     content: "Ürün " + suanOkutulanRaf + " Rafında",
        //     key,
        //     duration: 2,
        //   });
        // } else {
        //   message.error({ content: "Ürün Bulunamadı!", key, duration: 2 });
        // }

        setRaflar(temp);
      }
    }
  };

  const urunAdetiToplamAdetEsitmi = (raf_id, urunler) => {
    const toplamUrun = urunler.reduce((a, b) => a + b.adet, 0);

    const okutulanUrun = urunler.reduce((a, b) => {
      if (b.ayrim_adet === b.adet) {
        return a + b.adet;
      } else {
        return a;
      }
    }, 0);

    if (toplamUrun === okutulanUrun) {
      return true;
    } else {
      return false;
    }
  };

  // const rafislem = (id) => {
  //   const key = "updatable";
  //   axios.put("rafislem/" + id).then((result) => {
  //     if (result.data.status === 1) {
  //       message.success({ content: result.data.message, key, duration: 2 });
  //     } else {
  //       message.error({
  //         content: "Veri tabanı İşlem sırasında bir hata oluştu",
  //         key,
  //         duration: 2,
  //       });
  //     }
  //   });
  // };

  React.useEffect(() => {
    setBreadcrumb("Sepet (" + params.id + ")");
  }, [setBreadcrumb, params.id]);

  if (loading) return <Spin indicator={antIcon} />;

  return (
    <>
      <Input
        autoFocus={true}
        placeholder="Ürün Okutun"
        size="large"
        value={barkod}
        onKeyDown={urunOkut}
        onChange={(e) => setBarkod(e.target.value)}
      />
      <Row gutter={16}>
        {raflar.map((raf, key) => {
          return (
            <Fragment key={key}>
              <Col
                className="gutter-row"
                span={4}
                onClick={() => {
                  if (bittimi && raf.aktif) {
                    history.push("/sepet/detay/" + raf.siparis_id);
                  }
                }}
              >
                {raf.eksik_urun_durum !== 0 && (
                  <span
                    className="position-absolute bg-primary rounded text-white"
                    style={{
                      top: 5,
                      right: 0,
                      padding: "2px 5px",
                      fontSize: 12,
                    }}
                  >
                    {raf.eksik_urun_durum === 1
                      ? "Bekliyor"
                      : raf.eksik_urun_durum === 2
                      ? "Onaylandı"
                      : "Reddedildi"}
                  </span>
                )}
                <div
                  style={{
                    ...gridStyle,
                    background: raf.aktif
                      ? suanOkutulanRaf === raf.id
                        ? "#3959cc"
                        : urunAdetiToplamAdetEsitmi(raf.id, raf.siparis.urunler)
                        ? "#81b214"
                        : "#ffcc29"
                      : "gray",
                  }}
                >
                  <Row>
                    <Col span={12}>
                      <h1
                        style={{
                          fontSize: 40,
                          fontWeight: "bold",
                          color: "#fff",
                          textAlign: "left",
                          marginBottom: 5,
                          paddingLeft: 10,
                        }}
                      >
                        {raf.raf_adi}
                      </h1>
                      <h1
                        style={{
                          fontSize: 22,
                          fontWeight: "normal",
                          color: "#fff",
                          textAlign: "left",
                          paddingLeft: 10,
                        }}
                      >
                        {raf.siparis_id}
                      </h1>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        fontSize: 30,
                        color: "#fff",
                        textAlign: "right",
                      }}
                    >
                      <Row>
                        <Col span={24} style={{ paddingRight: 10 }}>
                          {raf.siparis.urunler.reduce((a, b) => a + b.adet, 0)}/
                          {raf.siparis.urunler.reduce(
                            (a, b) => a + b.ayrim_adet,
                            0
                          )}
                        </Col>
                        <Col span={24}>
                          {raf.siparis_detay !== null && (
                            <img
                              alt="kargo icon"
                              width="50"
                              height="50"
                              style={{ objectFit: "contain" }}
                              src={
                                raf.siparis_detay?.kargo === 1
                                  ? "https://cdn.modamizbir.com/assets/images/arasicon.png"
                                  : raf.siparis_detay?.kargo === 2
                                  ? "https://cdn.modamizbir.com/assets/images/fedexicon.png"
                                  : raf.siparis_detay?.kargo === 3
                                  ? "https://cdn.modamizbir.com/assets/images/kargoisticon.png" 
                                  : raf.siparis_detay?.kargo === 4 
                                  ? "https://cdn.modamizbir.com/assets/images/kargomsende.png"
                                  : raf.siparis_detay?.kargo === 5 
                                  ? "https://cdn.modamizbir.com/assets/images/surat.jpeg" : null

                              }
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Fragment>
          );
        })}
      </Row>
    </>
  );
}

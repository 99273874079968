import React, { useRef } from "react";
import {
  Input,
  message,
  PageHeader,
  Modal,
  Button,
  Spin,
  Row,
  Col,
} from "antd";
import {
  PrinterOutlined,
  ExportOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useParams, useHistory } from "react-router-dom";
import axios from "./CustomAxios";
import Etiket from "./etiket";
import ModalComponent from "./modal.component";
import { WritePrinter } from "./WritePrinter";

const Barcode = require("react-barcode");

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function SiparisDetay({ setBreadcrumb, userData }) {
  //Linkten idyi alır
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const etiketRef = useRef();

  const [loading, setLoading] = React.useState(true);

  //const [isLogin, setIsLogin] = React.useState(false);

  const [data, setData] = React.useState(null);
  // const [userData, setUserData] = React.useState(null);

  const [siparisDurum, setSiparisDurum] = React.useState(0);

  const [ayar, setAyar] = React.useState(null);
  const [urunler, setUrunler] = React.useState([]);
  const [siparisDetay, setSiparisDetay] = React.useState([]);
  const [adresMahalle, setAdresMahalle] = React.useState([]);
  const [adresIl, setAdresIl] = React.useState([]);
  const [adresIlce, setAdresIlce] = React.useState([]);

  const [yazdirVisible, setYazdirVisible] = React.useState(false);

  const [barkod, setBarkod] = React.useState("");
  const [toplamUrun, setToplamUrun] = React.useState(0);

  const [isOpened, setIsOpened] = React.useState(false);

  const [getGecmisHareketler, setGecmisHareketler] = React.useState(null);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [personelSiparisi, setPersonelSiparisi] = React.useState(0);

  
  const openFromParent = (id) => {
    axios
      .post("gecmis-raf-hareketleri", { varyasyon_id: id })
      .then((result) => {
        // console.log(result.data);
        setGecmisHareketler(result.data);
        setIsOpen(true);
      });
  };


  function handleCloseModal(event, data) {
    // console.log(event, data);
    setIsOpen(false);
  }

  function handleAfterOpen(event, data) {
    // console.log(event, data);
  }
  function toggle() {
    setIsOpened((wasOpened) => !wasOpened);
  }
  // const checkLogined = React.useCallback(() => {
  //   axios
  //     .post("check")
  //     .then((res) => {
  //       if (res.data.status === 1) {
  //         setIsLogin(true);
  //         setLoading(false);
  //         setUserData(res.data.user);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // }, []);

  const siparis = React.useCallback(() => {
    axios.get("siparisdetay/" + id).then((result) => {
      setAyar(result.data.ayar);

      setUrunler(result.data.siparis.urunler);
      setSiparisDetay(result.data.siparis.siparis_detay);
      setAdresMahalle(result.data.siparis.siparis_detay.adres_mahalle);
      setAdresIl(result.data.siparis.siparis_detay.adres_il);
      setAdresIlce(result.data.siparis.siparis_detay.adres_ilce);
      setData(result.data.siparis);

      setSiparisDurum(result.data.siparis.siparis_detay.durum);

      setPersonelSiparisi(
        result.data.siparis.siparis_detay?.uye?.personel ?? 0
      );

      let genelToplam = parseFloat(
        result.data.siparis.siparis_detay.genel_toplam
      );

      let genelToplam2 = parseFloat(
        result.data.siparis.urunler.reduce(
          (a, b) =>
            a +
            parseFloat(b.sepeturun?.ara_toplam) +
            parseFloat(b.sepeturun?.kdv_toplam),
          0
        ) +
          parseFloat(result.data.siparis.siparis_detay.kargo_tutari) +
          parseFloat(result.data.siparis.siparis_detay.kapida_odeme_tutari)
      );

      let fark = 4.0;
      if (Math.abs(genelToplam2 - genelToplam) > fark) {
        // console.log("fark");
        Modal.error({
          title: "Uyarı",
          content:
            "Sepet Toplamı : " +
            genelToplam2 +
            " Sipariş Toplamı " +
            genelToplam +
            "     Fiyat farklılığı vardır. Muhasebeye bilgi veriniz.",
        });
        setYazdirVisible(false);
      } else {
        setYazdirVisible(true);
      }

      setLoading(false);
    });
  }, [id]);

  const urunOkut = (e) => {
   
    if (e.key === "Enter") {
      if(userData.istasyon_id == 4){

        let userInput = prompt("Sipariş Numarası Girin:");
  
        // Girişin içeriğini kontrol et ve göster
        if (userInput == id) {
           
        } else {
            alert("İşlem Başarısız!.");
            return;
        }
     }

      // e.preventDefault();

      let temp = [...urunler];

      let bulunan = 0;
      console.log(urunler)
      // if (barkod.length > 11) {
      bulunan = temp.findIndex(
        (urun) =>
          (urun.barkod === barkod || urun.varyasyon.Barkod == barkod || String(urun.varyasyon_id) === barkod) &&
          urun.kontrol_adet !== urun.adet
      );

      // } else {
      //   bulunan = temp.findIndex(
      //     (urun) =>
      //       String(urun.varyasyon_id) === barkod &&
      //       urun.kontrol_adet !== urun.adet
      //   );
      // }

      if (bulunan !== -1) {
        temp[bulunan].kontrol_adet += 1;
        //      temp[bulunan].sepeturun.eksik = null;
        temp[bulunan].sepeturun.eksik =
          temp[bulunan].kontrol_adet < temp[bulunan].adet ? 1 : null;
        const key = "updatable";
        message.loading({
          content: "Kaydediliyor...",
          key,
          duration: 0,
        });

        axios
          .put("kontrolislem/" + temp[bulunan].id, {
            kontrol_adet: temp[bulunan].kontrol_adet,
          })
          .then((result) => {
            if (result.data.status === 1) {
              message.success({
                content: result.data.message,
                key,
                duration: 2,
              });

              //ürün okutma işlemi başarılıysa işlemleri gerçekleştir
              setUrunler((urunler) => temp);

              if (urunler.length > 0 && urunlerinHepsiOkutulmusmu() === true) {
                if (personelSiparisi === 1) {
                  modamizbirBarkodYazdir(1);
                } else {
                  arasKargoBarkodYazdir();
                }

                e.target.blur();
              }
            } else {
              message.error({
                content: "Veri tabanı İşlem sırasında bir hata oluştu",
                key,
                duration: 2,
              });
            }
          });
      } else {
        Modal.error({
          title: "Ürün Bulunamadı",
          content: "Okuttuğunuz Ürün Bulunamadı veya Daha Önce Okuttunuz...",
        });
      }

      //okutulan barkodu sıfırla
      setBarkod("");
    }
  };

  const renkKontrol = (urun) => {
    if (urun.kontrol_adet === urun.adet) {
      return "#00ff80";
    } else if (urun.kontrol_adet > 0) {
      return "#efff00";
    }
  };
  //Eksik ürün bildir
  const eksikbildir = (id) => {
    axios.post("eksikbildir/" + id).then((result) => {
      let temp = [...urunler];
      // console.log(temp);
      let index = temp.findIndex((x) => x.sepeturun.id === id);

      temp[index].kontrol_adet = 0;
      temp[index].sepeturun.eksik = 1;

      setUrunler(temp);

      // console.log(result.data);
    });
  };
  const raftransfer = () => {
    axios.post("raftransfer", { raf_adi: data.raf.raf_adi }).then((result) => {
      let printer_name = "zebra2";
      WritePrinter(result.data.zpl, printer_name);
      console.log('raf_transfer_yazdirma_gonderildi')
      console.log(result.data.zpl)
      message.success(result.data.message, 3);
      history.goBack();
    });
  };

  //ürünlerin hepsi okutulduğunu kontrol etme
  const urunlerinHepsiOkutulmusmu = () => {
    let bitensayisi = 0;
    urunler.forEach((urun) => {
      if (urun.kontrol_adet === urun.adet) {
        bitensayisi++;
      }
    });
    if (bitensayisi !== urunler.length) {
      return false;
    } else {
      return true;
    }
  };

  const eksikVarMi = () => {
    let eksiksayisi = 0;
    urunler.forEach((urun) => {
      // console.log(urun);
      if (urun.sepeturun?.eksik == 1) {
        eksiksayisi++;
      }
    });
    if (eksiksayisi > 0) {
      return true;
    } else {
      return false;
    }
  };

  const eksikSayisi = () => {
    let eksiksayisi = 0;
    urunler.forEach((urun) => {
      // console.log(urun);
      if (urun.sepeturun?.eksik == 1) {
        eksiksayisi++;
      }
    });
    return eksiksayisi;
  };

  const modamizbirBarkodYazdir = (printable) => {
    if (urunlerinHepsiOkutulmusmu() === false) {
      const key = "updatable";
      message.error({
        content: "Kontrol edilmemiş ürünler bulunmaktadır.",
        key,
        duration: 2,
      });
      return;
    }

    const key = "updatable";
    message.loading({
      content: "İşlem gerçekleştiriliyor lütfen bekleyiniz...",
      key,
      duration: 0,
    });

    setTimeout(() => {
      axios.get("etiketyazdir/" + id).then((result) => {
        if (result.data.status === 1) {
          message.success({
            content: "Başarılı",
            key,
            duration: 2,
          });

          if (printable === 1) {
            let a = window.open("", "", "height=500, width=500");
            a.document.write("<html>");
            a.document.write("<body>");
            a.document.write(`
            <style>
                body{
                    margin: 0px;
                    padding: 0px;
                    font-family: Arial, Helvetica, sans-serif;
                }
                .genel {                    
                    font-size: 11px !important; 
                }
                .baslik {
                    font-weight:bold; margin-bottom: 7px;
                }
                .barkod {
                    font-weight: bold !important;
                }
                .deger {
                    font-size: 26px; font-weight: bold !important;
                }
                .adet {
                    font-size: 75px;
                }
                .genel-text {
                    font-size: 11px !important;
                }
                .cerceve {
                    height: auto;
                    width: 390px;
                    border-radius: 10px;
                    border: 2px solid #000;
                    padding: 5px;
                }
                .cerceve-personel {
                    height: auto;
                    width: 390px;
                    border-radius: 10px;
                    border: 4px dashed #000;
                    padding: 5px;
                }
                .baslik {
                    height: auto;
                    width: 395px;
                    padding: 3px;
                }
                .footer {
                    font-size: 10px;
                    font-style: normal;
                    margin-top: 2px;
                }
                .sol {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: bolder;
                }
                .adres    {
                    text-align: justify;
                }
              h1 {
                font-size: 22px !important;
                font-style: normal !important;
                font-weight: bolder !important;
              }
              .barcode-personel{
                display:none;
              }
            </style>
            `);
            a.document.write(etiketRef.current?.innerHTML);
            a.document.write("</body></html>");
            a.document.close();

            a.onload = function () {
              a.print();
            };

            setTimeout(() => {
              a.close();
            }, 200);
          }
        } else {
          message.error({
            content: result.data.message,
            key,
            duration: 3,
          });
        }
      });
    }, 200);
  };

  const arasKargoBarkodYazdir = () => {
    if (urunlerinHepsiOkutulmusmu() === false) {
      const key = "updatable";
      message.error({
        content: "Kontrol edilmemiş ürünler bulunmaktadır.",
        key,
        duration: 2,
      });
      return;
    }

    // const key = "updatable"
    message.loading({
      content: "İşlem gerçekleştiriliyor lütfen bekleyiniz...",
      key: "updatable",
      duration: 0,
    });

    axios
      .get("arasetiketyazdir/" + id)
      .then((result) => {
        let printer_name =
          data?.siparis_detay?.kargo === 1 ? "zebra1" : "zebra2";

        if (result.data.status === 1) {
          message.success({
            content: "Başarılı",
            key: "updatable",
            duration: 2,
          });
          WritePrinter(result.data.aras_zpl, printer_name);
         // window.writeToSelectedPrinter(result.data.aras_zpl, printer_name);
        } else {
          message.error({
            content: result.data.message,
            key: "updatable",
            duration: 3,
          });
          WritePrinter(result.data.aras_zpl, printer_name);
        //  window.writeToSelectedPrinter(result.data.aras_zpl, printer_name);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          message.error({
            content: "Hata meydana geldi.",
            key: "updatable",
            duration: 3,
          });
        }
      });
  };
  

  const siparisIptal = () => {
    if(userData.istasyon_id == 4){

      let userInput = prompt("Sipariş Numarası Girin:");

      // Girişin içeriğini kontrol et ve göster
      if (userInput == id) {
         
      } else {
          alert("İşlem Başarısız!.");
          return;
      }
   }
    axios
      .post("eksikurunislem", { raf: data.raf.raf_adi, i: "siparisIptal" })
      .then((result) => {
        history.goBack();
        message.success(result.data.message, 3);
      });
  };

  const CuvalBildir = () => {
    var onay = window.confirm("Onaylıyor musunuz?");
    // Kullanıcının seçimine göre işlemleri gerçekleştirme
    if (onay) {
      setLoading(true);
      axios
      .post("cuvalkargodegistir/" + data?.siparis_detay?.id)
      .then(() => {
        setLoading(false);
      });
    } else {
      return;
    }
  }

  const eksikUrunOnayla = () => {
    if(userData.istasyon_id == 4){

      let userInput = prompt("Sipariş Numarası Girin:");

      // Girişin içeriğini kontrol et ve göster
      if (userInput == id) {
         
      } else {
          alert("İşlem Başarısız!.");
          return;
      }
   }
        axios
        .post("eksikurunislem", { raf: data.raf.raf_adi, i: "eksikUrunOnayla" })
        .then((result) => {
          message.success(result.data.message, 3);
          setLoading(true);
          //sipariş tekrar çekme
          siparis();
      });
  };

  const siparisiRaftanKaldir = () => {
    if (
      window.confirm(
        id +
          " siparişi " +
          data?.raf?.raf_adi +
          " rafından kaldırmak için emin misiniz?"
      )
    ) {
      axios.delete("siparisi-raftan-kaldir/" + id).then((res) => {
        if (res.data.status === 0) {
          message.error("Hata meydana geldi.");
        }

        history.goBack();
      });
    }
  };

  const durumDegistir = () => {
    if (data.raf.eksik_urun_durum === 0) {
      axios
        .post("eksik-urun-siparis-durum-degistir", {
          id: data.raf.id,
          durum_id: 1,
        })
        .then((res) => {
          // setRaflar(raflar.filter((x) => x.id !== id));

          message.success("Başarılı");
          history.goBack();
        });
    }
  };

  const escFunction = React.useCallback(
    (event) => {
      if (event.keyCode === 27) {
        history.goBack();
      }
    },
    [history]
  );

  React.useEffect(() => {
    setToplamUrun(urunler.reduce((a, b) => a + b.adet, 0));
  }, [urunler]);

  React.useEffect(() => {
    setBreadcrumb("Sipariş Detay (" + id + ")");
  }, [setBreadcrumb, id]);

  React.useEffect(() => {
    siparis();
  }, [siparis]);

  React.useEffect(() => {
    window.addEventListener("keydown", escFunction, false);
    return () => window.removeEventListener("keydown", escFunction, false);
  }, [escFunction]);

  React.useEffect(() => {
    if (siparisDurum === 4) {
      Modal.error({
        title: "Uyarı",
        content: "Sipariş iptal edilmiş",
      });
    }
  }, [siparisDurum]);

  if (loading) return <Spin indicator={antIcon} />;

  return (
    <>
    
    
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={"Raf " + data?.raf?.raf_adi + " -  Sipariş " + id}
        extra={[
          <img
            key="1"
            width="70"
            height="58"
            style={{ objectFit: "contain" }}
            alt="cargo icon"
            // src={
            //   data?.siparis_detay?.kargo === 1
            //     ? "https://cdn.modamizbir.com/assets/images/arasicon.png"
            //     : "https://cdn.modamizbir.com/assets/images/fedexicon.png"
            // }
            src={
              data?.siparis_detay?.kargo === 1
                ? "https://cdn.modamizbir.com/assets/images/arasicon.png"
                : data?.siparis_detay?.kargo === 2
                ? "https://cdn.modamizbir.com/assets/images/fedexicon.png"
                : data?.siparis_detay?.kargo === 3
                ? "https://cdn.modamizbir.com/assets/images/kargoisticon.png"
                : data?.siparis_detay?.kargo === 4
                ? "https://cdn.modamizbir.com/assets/images/kargomsende.png"
                : null
            }
          />,
        ]}
        // subTitle={id + " Numaralı Sipariş"}
        style={{
          // border: "1px solid #e0e0e0",
          borderRadius: 3,
          background: "#eee",
        }}
      />
      <br />
      <Input
        autoFocus
        placeholder="Ürün Okutun"
        size="large"
        value={barkod}
        onKeyDown={urunOkut}
        onChange={(e) => setBarkod(e.target.value)}
        className="mb-4"
      />
      {data?.siparis_detay?.kargo == 6 ?
        <>
      <Button
        className="mt-2 col-2"
        type="primary"
        size="large"
        style={{'float':"left"}}
        onClick={CuvalBildir}
        block
      > 
      Çuval Bildir
      </Button>
      <div className="col-10" style={{height:'80px',float:'left'}}></div>
      </>
       : null}
      <ModalComponent
        dynData={getGecmisHareketler}
        IsModalOpened={modalIsOpen}
        onCloseModal={handleCloseModal}
        onAfterOpen={handleAfterOpen}
      />
      <div className="row" style={{ flexWrap: "wrap" }}>
        <table className="table table-striped table-striped text-center">
          <thead>
            <tr>
              <th scope="col">Resim</th>
              {userData.istasyon_id === 4 && <th scope="col">Raf</th>}
              <th scope="col">Okutulan</th>
              <th scope="col">Adet</th>
            </tr>
          </thead>
          <tbody>
            {urunler.map((item, key) => {
              // console.log(item.sepeturun.eksik);
              if (item.sepeturun) {
                return (
                  <tr
                    key={key}
                    style={
                      item.kontrol_adet !== 0
                        ? { background: renkKontrol(item) }
                        : {}
                    }
                  >
                    <td>
                      <img height="150" src={item.resim} alt="img" />
                    </td>
                    {userData.istasyon_id === 4 && (
                      <td>
                        <button
                          className="btn btn-warning btn-sm mb-2"
                          onClick={() =>
                            window.open(
                              "https://panel2.pembebeyaz.com/urunler/duzenle/" +
                                item.sepeturun.urun_id,
                              "_blank"
                            )
                          }
                        >
                          Ürün Detay
                        </button>
                        <br />
                        <button
                          className="btn btn-primary btn-sm mb-2"
                          onClick={() => openFromParent(item.varyasyon_id)}
                        >
                          Geçmiş
                        </button>
                        <br />
                        {item.raflar.map((element) => {
                          if (element.adet > 0)
                            return (
                              <>
                                <span>
                                  {element.raf} - ({element.adet})
                                </span>
                                <br />
                              </>
                            );
                        })}
                      </td>
                    )}
                    <td>
                      <h2>
                        {item.sepeturun.varyasyon_adi + " - "}
                        <Barcode
                          height="100"
                          background="transparent"
                          value={item.varyasyon_id ?? item.barkod}
                        />
                        {item.varyasyon.Barkod != null ?
                        <div style={{fontSize:'20px'}}>{'Eşdeğer barkod : ' + item.varyasyon.Barkod }</div> : null}
                      </h2>
                      {item.sepeturun.eksik === 1 ? (
                        <h2>Eksik Ürün</h2>
                      ) : (
                        <div
                          className="btn btn-danger btn-sm"
                          onClick={() => eksikbildir(item.sepeturun.id)}
                        >
                          Eksik Ürün
                        </div>
                      )}
                    </td>
                    <td>
                      <h2 className="mb-4">
                        {item.adet + " / " + item.kontrol_adet}
                      </h2>
                      <h4>{item.sepeturun.fiyati} TL</h4>
                      <h4>{item.sepeturun.ara_toplam} TL</h4>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      {siparisDurum !== 4 &&
        yazdirVisible &&
        !eksikVarMi() &&
        !personelSiparisi && (
          <>
            {ayar?.kargo_butonu === 1 && (
              <Button
                icon={<PrinterOutlined />}
                type="primary"
                size="large"
                onClick={() => modamizbirBarkodYazdir(1)}
                block
              >
                Kargo Bildir ve Modamızbir Barkod Yazdır
              </Button>
            )}

            <br />
            <br />

            {ayar?.aras_kargo_butonu === 1 && (
              <Button
                icon={<PrinterOutlined />}
                type="primary"
                size="large"
                onClick={arasKargoBarkodYazdir}
                block
              >
                Kargo Barkod Yazdır
              </Button>
            )}

            <br />
            <br />

            {ayar?.kargo_butonu === 1 && (
              <Button
                icon={<PrinterOutlined />}
                size="large"
                onClick={() => modamizbirBarkodYazdir(0)}
                style={{ backgroundColor: "orange" }}
                block
              >
                Sadece Kargo Bildir
              </Button>
            )}
          </>
        )}
      {yazdirVisible && !eksikVarMi() && personelSiparisi ? (
        <>
          {ayar?.kargo_butonu === 1 && (
            <Button
              icon={<PrinterOutlined />}
              type="primary"
              size="large"
              onClick={() => modamizbirBarkodYazdir(1)}
              block
            >
              Personel Barkodu Yazdır
            </Button>
          )}
        </>
      ) : (
        ""
      )}
      <br />
      {userData?.istasyon_id !== 4 && (
        <>
          <br />
          <Button
            icon={<ExportOutlined />}
            type="danger"
            size="large"
            onClick={raftransfer}
            block
          >
            Transfer
          </Button>
        </>
      )}
    
      {userData?.istasyon_id === 4 && (
        <>
          {eksikSayisi() > 0 && data.raf.eksik_urun_durum === 0 && (
            <Button
              className="mt-4"
              type="primary"
              size="large"
              onClick={durumDegistir}
              block
            >
              Çağrı Merkezine Yönlendir
              {/*data.raf.eksik_urun_durum === 0
                ? "Çağrı Merkezine Yönlendir"
                : data.raf.eksik_urun_durum === 1
                ? "Çağrı Merkezi Cevabı: Bekliyor"
                : data.raf.eksik_urun_durum === 2
                ? "Çağrı Merkezi Cevabı: Onaylandı"
          : "Çağrı Merkezi Cevabı: Reddedildi"*/}
            </Button>
          )}

          <Button
            className="mt-4"
            type="danger"
            size="large"
            onClick={siparisiRaftanKaldir}
            block
          >
            Siparişi Raftan Kaldır
          </Button>
        </>
      )}
      {userData?.istasyon_id === 4 && (
        <>
          <Row className="col-12 m-0 p-0 mt-4">
            <Col md={12}>
              <Button
                icon={<ExportOutlined />}
                type="danger"
                size="large"
                onClick={siparisIptal}
                block
              >
                Siparişi İptal Et
              </Button>
            </Col>
            {urunler.length - eksikSayisi() > 0 && (
              <Col md={12}>
                <Button
                  icon={<ExportOutlined />}
                  type="primary"
                  size="large"
                  onClick={eksikUrunOnayla}
                  block
                >
                  Eksik Ürünü Onayla
                </Button>
              </Col>
            )}
          </Row>
       
          <div className="section inset mt-2 mb-2">
            <div id="accordion1b" className="card mt-1 show">
              <button
                className="btn btn-block text-left outline-0 p-3"
                type="button"
                onClick={toggle}
              >
                Sipariş ve İletişim Bilgileri
              </button>
              {isOpened && (
                <div className="card-body">
                  <div className="row">
                    <div className="col-4">
                      <h3>Müşteri İletişim Bilgileri</h3>
                      <ul
                        className="listview flush transparent simple-listview no-space mt-3 pl-0"
                        style={{ fontSize: "16px" }}
                      >
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eee",
                            padding: "10px 0px",
                          }}
                        >
                          <strong>Adı Soyadı</strong>
                          <span className=" text-success">
                            {siparisDetay.ad} {siparisDetay.soyad}
                          </span>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eee",
                            padding: "10px 0px",
                          }}
                        >
                          <strong>Telefon Numarası</strong>
                          <span className="text-danger">
                            <a href="tel:5438231286">{siparisDetay.telefon}</a>{" "}
                          </span>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eee",
                            padding: "10px 0px",
                          }}
                        >
                          <strong>Adres</strong>
                          <span>{siparisDetay.adres}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-4">
                      <h3>Fatura Bilgileri</h3>
                      <p className="mb-3"></p>
                      <ul
                        className="listview flush transparent simple-listview no-space mt-3 pl-0"
                        style={{ fontSize: "16px" }}
                      >
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eee",
                            padding: "10px 0px",
                          }}
                        >
                          <strong>Adı Soyadı</strong>
                          <span className="text-success">
                            {siparisDetay.fatura_ad} {siparisDetay.fatura_soyad}
                          </span>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eee",
                            padding: "10px 0px",
                          }}
                        >
                          <strong>Telefon Numarası</strong>
                          <span className="text-danger">
                            {siparisDetay.telefon}
                          </span>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eee",
                            padding: "10px 0px",
                          }}
                        >
                          <strong>Adres</strong>
                          <span>{siparisDetay.fatura_adres}</span>
                        </li>
                      </ul>
                      <p></p>
                    </div>
                    <div className="col-4">
                      <h3>Sipariş Bilgileri</h3>
                      <p className="mb-3"></p>
                      <ul
                        className="listview flush transparent simple-listview no-space mt-3 pl-0"
                        style={{ fontSize: "16px" }}
                      >
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eee",
                            padding: "10px 0px",
                          }}
                        >
                          <strong>Ödeme Türü</strong>
                          <span>
                            {" "}
                            {siparisDetay.odeme_turu == 1
                              ? "Kapıda Ödeme"
                              : "Kredi Kartı"}{" "}
                          </span>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eee",
                            padding: "10px 0px",
                          }}
                        >
                          <strong>Sipariş Tarihi</strong>
                          <span>{siparisDetay.created_at}</span>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eee",
                            padding: "10px 0px",
                          }}
                        >
                          <strong>Genel Toplam</strong>
                          <h3 className="m-0">{siparisDetay.genel_toplam}</h3>
                        </li>
                      </ul>
                      <p></p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <div className="row d-none">
        <div className="col" id="etiket" ref={etiketRef}>
          <Etiket
            personel={personelSiparisi}
            id={id}
            ad={siparisDetay.ad}
            soyad={siparisDetay.soyad}
            mahalle_adi={adresMahalle.mahalle_adi}
            adres={siparisDetay.adres}
            il_adi={adresIl.il_adi}
            ilce_adi={adresIlce.ilce_adi}
            telefon={siparisDetay.telefon}
            odeme_turu={siparisDetay.odeme_turu}
            toplam_urun_sayisi={toplamUrun}
            kargo_tutari={siparisDetay.kargo_tutari}
            komisyon_tutari={siparisDetay.komisyon_tutari}
            kapida_odeme_tutari={siparisDetay.kapida_odeme_tutari}
            genel_toplam={siparisDetay.genel_toplam}
            siparis_tutari={siparisDetay.siparis_tutari}
            kdv_tutari={siparisDetay.kdv_tutari}
          />
        </div>
      </div>
    </>
  );
}
